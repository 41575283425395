import request from '@/utils/request'


// 租户查询仓库详细
export function getHomeInfo() {
  // const tenantId = store.getters && store.getters.tenantId
  return request({
    url: '/wms/home/homeInfo',
    method: 'get'
  })
}

// 仓库容器库存统计
export function getCapacityInfo(varietyCode){
  return request({
    url: '/wms/home/capacityInfo/' + varietyCode,
    method: 'get'
  })
}

// 仓库容器库存统计
export function getCapacityPieInfo(varietyCode){
  return request({
    url: '/wms/home/capacityPieInfo/'+ varietyCode,
    method: 'get'
  })
}
// 仓库近7日的日单量统计
export function capacityFormCountInfo(varietyCode){
  return request({
    url: '/wms/home/capacityFormCountInfo/'+ varietyCode,
    method: 'get'
  })
}

export function capacityHalfYearInfo(varietyCode) {
  return request({
    url: '/wms/home/capacityHalfYearInfo/' + varietyCode,
    method: 'get',
  })
}

// 库房信息统计
export function getRoomInfo(){
  return request({
    url: '/wms/home/roomStatistics',
    method: 'get'
  })
}

// 新增事务统计
export function getEventInfo(){
  return request({
    url: '/wms/home/eventStatistics',
    method: 'get'
  })
}


// 待办事项统计
export function getBackLogInfo(varietyCode){
  return request({
    url: '/wms/home/backLogStatistics/'+ varietyCode,
    method: 'get'
  })
}

// 获取监管图片
export function upmsFilePath(filePath){
  return request({
    url: '/supervise/file/downloadFile/',
    method: 'get',
    params: {
      filePath
    }
  })
}



