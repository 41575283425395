<template>
  <div class="file-container">
    <transition-group class="file-list" name="el-fade-in-linear" tag="ul">
      <li v-for="(file, index) in fileList" :key="file.url" class="file-list-item"
        style=" display: flex; justify-content: center">
        <div v-if="file.type === 'pdf' || file.type === 'PDF'" class="pdf">
          <el-image :src="pdfImage" style="width: 110px; height: 100px;" fit="contain"></el-image>
        </div>
        <div v-else-if="file.type === 'rar' || file.type === 'zip'" class="pdf">
          <el-image :src="rarImage" style="width: 110px; height: 100px;" fit="contain"></el-image>
        </div>
        <div v-else-if="file.type === 'doc' || file.type === 'docx'" class="pdf">
          <el-image :src="wordImage" style="width: 110px; height: 100px;" fit="contain"></el-image>
        </div>
        <el-image v-else class="image" :src="file.url" fit="contain" style="width: 110px; height: 100px;color: black;" />
        <span style="position: absolute; bottom: -10px; display: inline;font-size: 11px;" v-if="!isSuper">
          上传日期:{{ file.url.match(/\b\d{8}\b/) ? file.url.match(/\b\d{8}\b/)[0].replace(/(\d{4})(\d{2})(\d{2})/,
            '$1-$2-$3') : '' }}
        </span>
        <div class="file-list-item-action">
          <el-icon color="#fff" title="点击预览" @click="handlerPreview(file.type, file.url, index)"
            v-if="file.type !== 'rar' && file.type !== 'zip' && file.type !== 'doc' && file.type !== 'docx'">
            <View />
          </el-icon>
          <el-icon color="#fff" title="点击下载" @click="downloadFile(file.url, file.type)"
            v-if="file.type === 'rar' || file.type === 'zip' || file.type === 'pdf' || file.type === 'PDF' || file.type === 'doc' || file.type === 'docx'">
            <Download />
          </el-icon>
          <el-icon v-if="scene === 'add' && isDelete" color="#fff" title="点击删除" @click="handleDelete(index)">
            <delete />
          </el-icon>
        </div>
      </li>
      <li v-if="fileList.length < limit" class="file-list-item" style=" padding: 0; border: none;">
        <el-upload ref="uploadFileRef" v-model="value" :action="isSuper ? uploadUrlSuper : uploadUrl"
          :show-file-list="showFileList" :before-upload="handleBeforeUpload" :on-success="uploadSuccess"
          :on-exceed="handleExceed" :on-error="uploadError" :limit="limit" :headers="headers" :multiple="multiple"
          :data="isSuper ? otherData : data" :accept="accept" class=""
          style=" display: flex; align-items: center; justify-content: center; width: 120px; height: 120px; padding: 5px; font-size: 30px; color: rgba(0, 0, 0, .4); border: rgba(0, 0, 0, .1) 1px dashed; border-radius: 4px;">
          <el-icon v-if="!disabled" class="avatar-uploader-icon">
            <plus />
          </el-icon>
          <el-icon v-if="disabled" class="is-loading">
            <Loading />
          </el-icon>
        </el-upload>
      </li>
    </transition-group>

    <el-dialog title="预览文档" v-model="dialogVisible" width="80%">
      <PDFView v-if="jsPdf" :pdfUrl="jsPdf" />
    </el-dialog>
  </div>
</template>
<script setup name="UploadFile">
import { ref, reactive, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus'
import { getToken } from '@/utils/auth'
import { getFileUrl } from '@/api/wms/file'
import { preview } from "vue3-preview-image"
import PDFView from "./pdfPreview"
import pdfImage from '@/assets/images/pdf.png'
import rarImage from '@/assets/images/rar.png'
import wordImage from '@/assets/images/WORD.png'
import moment from 'moment'
import { upmsFilePath } from '@/api/wms/home/home'
const store = useStore()
const emit = defineEmits(['update:modelValue', 'uploadImage'])
const props = defineProps({
  scene: {
    type: String,
    default: 'add'
  },
  modelValue: String,
  mode: { // card：缩略图  list：列表
    type: String,
    default: 'card'
  },
  limit: {
    type: Number,
    default: 30
  },
  showFileList: {
    type: Boolean,
    default: false
  },
  accept: {
    type: String,
    default: 'jpg, gif, png, jpeg, tif, pdf, zip, rar'
  },
  // 是否开启多张上传功能，默认false 不开启
  multiple: {
    type: Boolean,
    default: false
  },
  isSuper: {
    type: Boolean,
    default: false
  },
  zipSize: {
    type: Number,
    default: 100
  },
  defaultSize: {
    type: Number,
    default: 10
  },
  isDelete: {
    type: Boolean,
    default: true
  }
})
const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    console.log()
    emit('update:modelValue', value)
  }
})
const uploadUrl = ref(import.meta.env.VITE_APP_BASE_API + '/file/minio/upload')
const uploadUrlSuper = ref(import.meta.env.VITE_APP_BASE_API + '/supervise/file/upload')
const headers = ref({ Authorization: "Bearer " + getToken() });
const data = reactive({
  dir: `${store.getters.tenantId}`
})
const otherData = reactive({
  fileType: 'image',
  dir: 'attachment/'
})
const fileList = ref([])
const currentType = ref()
const disabled = ref(false)
// 上传文件之前的钩子
const handleBeforeUpload = (rawFile) => {
  console.log(rawFile.type)
  currentType.value = rawFile.type.split('/')[1]
  //rar文件无法识别出类型，zip文件有三种MIME类型，所以直接判断后缀名
  const arr = rawFile.name.split('.')
  const type = arr[arr.length - 1]
  if (type === 'rar' || type === 'zip' || type === 'doc' || type === 'docx') {
    currentType.value = type
  }
  console.log(currentType.value, rawFile)
  if (props.accept.includes(currentType.value)) {
    if (currentType.value === 'rar' || currentType.value === 'zip') {
      console.log(rawFile.size / 1024 / 1024, props.zipSize)
      if (rawFile.size / 1024 / 1024 > props.zipSize) {
        ElMessage({
          message: `上传的压缩包大于 ${props.zipSize} M`,
          type: 'warning',
        })
        return false
      }
    } else if (rawFile.size / 1024 / 1024 > props.defaultSize) {
      ElMessage({
        message: `上传的文件大于 ${props.defaultSize} M`,
        type: 'warning',
      })
      return false
    }
  } else {
    ElMessage({
      message: '请选择正确的文件格式',
      type: 'warning',
    })
    return false
  }
  disabled.value = true
  return true
}
// 当超出限制时，执行的钩子函数
const handleExceed = () => {
  ElMessage({
    message: `上传文件数量不能超过 ${props.limit} 个!`,
    type: 'warning',
  })
}
// 阻止modelValue更新后重新下载图片导致组件闪烁问题
const isStopReview = ref(false)
// 文件上传成功时的钩子
const uploadSuccess = async (res, uploadFile, uploadFiles) => {
  disabled.value = true
  console.log(uploadFile, res)
  if (!props.isSuper) {
    if (res?.code === 200) {
      try {
        const { code, data } = await getFileUrl(res?.data?.link)
        if (code === 200) {
          disabled.value = false
          let arr = res?.data?.link.split('.')
          fileList.value.push({ ...uploadFile, link: res.data.link, url: data, type: arr[arr.length - 1] })
          console.log(fileList.value)
          isStopReview.value = true
          console.log(fileList.value.map(item => item.link).join())
          emit('update:modelValue', fileList.value.map(item => item.link).join())
          emit('uploadImage', fileList.value.filter(item => item.name).map(item => item.link).join())
        }
      } catch (error) {
        isStopReview.value = false
      }
    }
  } else {
    if (res?.link) {
      const res2 = await upmsFilePath(res?.link)
      disabled.value = false
      let arr = res.link.split('.')
      fileList.value.push({ ...uploadFile, link: res.link, url: res2.data, type: arr[arr.length - 1] })
      console.log(fileList.value)
      isStopReview.value = true
      const arr2 = fileList.value.map((item) => {
        return {
          name: item.name,
          url: item.link
        }
      })
      console.log(JSON.stringify(arr2))
      emit('update:modelValue', JSON.stringify(arr2))
    }
  }

}
// pdf 预览
const dialogVisible = ref(false)
const jsPdf = ref()
const handlerPreview = (type, url, index) => {
  if (type === 'pdf') {
    jsPdf.value = url
    dialogVisible.value = true
    return
  }
  const arr = fileList.value.filter(item => item.type !== 'pdf' && item.type !== 'rar' & item.type !== 'zip' && item.type !== 'doc' && item.type !== 'docx').map(item => item.url)
  console.log(index, arr)
  preview(index, arr)
}
const downloadFile = async (fileUrl, type) => {
  console.log(fileUrl, type)
  const link = document.createElement('a')
  link.href = fileUrl
  link.target = '_blank'
  link.download = `下载文件_${moment(new Date()).format('YYYY-MM-DD HH-mm-ss')}.${type}`;
  link.click()
};
const handleDelete = (index) => {
  fileList.value.splice(index, 1)
  isStopReview.value = true
  emit('update:modelValue', fileList.value.map(item => item.link).join())
  emit('uploadImage', fileList.value.filter(item => item.name).map(item => item.link).join())
}

// uploadError
const uploadError = () => {
  disabled.value = false
  ElMessage({
    message: `上传文件失败`,
    type: 'warning',
  })
}

watch(
  () => props.modelValue,
  async (val) => {
    console.log(isStopReview.value)
    if (val) {
      if (!isStopReview.value) {
        console.log(val)
        const images = await getImagesUrl(val)
        console.log(images)
        fileList.value = images
        isStopReview.value = false
      }
    } else {
      fileList.value = []
    }
  },
  {
    immediate: true,
    deep: true
  }
)

// 批量获取图片路径
async function getImagesUrl(pic) {
  let urls = []
  if (
    pic &&
    pic.split(",").length > 0
  ) {
    urls = (pic.split(",")).filter(item => item && item.trim())//去除空值
    return await Promise.all(
      urls.map(async (it) => {
        const type = it.substring(it.lastIndexOf('.') + 1)
        let res
        if (props.isSuper) {
          res = await upmsFilePath(it)
        } else {
          res = await getFileUrl(it)
        }
        return {
          type,
          link: it,
          url: res.data
        }
      })
    )
  }
}
defineExpose({
  fileList,
  isStopReview
})
</script>

<style lang="scss" scoped>
.file-container {
  display: flex;
  align-items: center;
  gap: 10px;

  .file-list {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;

    &-item {
      position: relative;
      width: 120px;
      height: 120px;
      padding: 5px;
      border: rgba(0, 0, 0, .1) 1px solid;
      border-radius: 4px;

      .pdf {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }

      .image {
        width: 90px;
        height: 90px;
      }

      &-action {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        padding: 20px;
        box-sizing: border-box;
        opacity: 0;
        border-radius: 4px;

        .el-icon {
          font-size: 18px;
          cursor: pointer;
        }
      }

      &:hover .file-list-item-action {
        background-color: rgba(0, 0, 0, .45);
        opacity: 1;
        transform: all linear 0.5s;
      }
    }
  }
}
</style>